import BulletPointSection from "components/common/BulletPointSection";
import FAQSection from "components/common/FAQSection";
import LogoSlider from "components/common/LogoSlider";
import G2Section from "components/home/G2Section";
import LandingPageHero from "components/landing-pages/heros/LandingPageHero";
import AnimatedScrollSteps from "components/landing-pages/referral/AnimatedScrollSteps";
import MobileStepsSections from "components/landing-pages/referral/MobileStepsSections";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import { Helmet } from "react-helmet";

export default function ReferralPage() {
  const renderContent = data => {
    const { isMobile } = useIsMobile(1024);

    // const highlights = [
    //   {
    //     icon: () => <IconMoney />,
    //     text:
    //       "Earn $100 for every referral with an annual spend of up to USD $5,000",
    //   },
    //   {
    //     icon: () => <IconMoneyBag />,
    //     text:
    //       "Enjoy up to $500 per referral if their spend surpasses USD $5,000",
    //   },
    // ];
    const QA = [
      {
        question: "How do I submit a referral?",
        answer:
          "Referrals must be submitted using the Rhombus Referral Form at the top of this page.",
      },
      {
        question: "Is there a time limit for someone to use my referral?",
        answer: "Yes, referrals expire 60 days after the submission date.",
      },
      {
        question: "How are rewards paid out?",
        answer: "Rewards are paid out in the form of digital gift cards.",
      },
      {
        question: "Is there a limit to the number of referrals I can submit?",
        answer:
          "There is no limit to the number of referrals you can submit—the more you refer, the more rewards you can earn. ",
      },
      {
        question:
          "Someone I referred made a purchase. When will I receive my reward?",
        answer:
          "Rewards are only remitted after the 60-day return period for the qualifying purchases has expired. Referrals are paid shortly after 60-days post purchase. ",
      },
      {
        question: "How much will my referral reward be?",
        answer:
          "Your reward amount will depend on how much your referral purchases when they first sign up with us. For annual spend (excluding taxes) of less than USD $5,000, the referral reward will be $100. For annual spend (excluding taxes) of more than or equal to USD $5,000, the referral reward will be $500.",
      },
    ];

    const STEPS = [
      {
        stepCount: 1,
        title: "Share Rhombus with a Friend",
        description:
          "Let your friend know about Rhombus as a possible solution to meet their needs. Complete the form above to submit your referral and put us in touch.",
        image: data.step1,
      },
      {
        stepCount: 2,
        title: "Your Referral Becomes a Rhombus Customer",
        description:
          "The Rhombus team will reach out to your referral to see what value we can provide for their business and offer a tailored solution for their needs.",
        image: data.step2,
      },
      {
        stepCount: 3,
        title: "Start Enjoying Your Rewards",
        description:
          "After your referral makes a purchase, you'll receive a digital gift card, up to $500*.",
        image: data.step3,
      },
    ];

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Refer a Friend | Rhombus™</title>
          <meta
            name="description"
            content="Unlock rewards with Rhombus. Refer a friend today and start earning cash rewards."
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/OG-referrals.png"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <LandingPageHero
          preTitle="Customer Referral Program"
          title="Earn Cash Rewards for Each Business You Refer"
          blurb="Unlock rewards with Rhombus. Refer a friend today and start earning up to $500 per referral.*"
          formId="6ec28c7d-e78c-45c4-a752-8ce41915331c"
          formTitle="Refer a Friend"
          formBlurb="Introduce us to a new business or organization. Complete the form to submit your referral, and Rhombus will follow up with them."
        />
        <LogoSlider />
        {isMobile ? (
          <MobileStepsSections steps={STEPS} title="Getting Started" />
        ) : (
          <AnimatedScrollSteps />
        )}
        <BulletPointSection
          bgColor="var(--nuetral-100)"
          title="The Fine Print "
          bullets={[
            "Current Rhombus customers in good standing can participate. ",
            "Rewards are based on the referral's initial purchase only. ",
            "Referrers must submit referrals via designated channels before sales engagement. ",
            "Rewards processed within 90 days after referral's purchase. ",
            "Referrals must make a purchase within 60 days for eligibility.",
            "Rewards are not applicable to State, Local, Education, and Government entities. ",
          ]}
          link={{
            title: "View full terms and conditions",
            path: "https://info.rhombus.com/rhombus-referral-program",
          }}
        />
        <G2Section
          bgColor="var(--white)"
          blurb="Rhombus has helped over 2,000 global customers secure 30,000+ locations."
        />
        <FAQSection
          QA={QA}
          title="FAQs"
          image={data.FAQ}
          reverse
          color="var(--nuetral-100)"
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      FAQ: file(
        relativePath: { eq: "components/landing-pages/img/referral-faq.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      step1: file(
        relativePath: {
          eq: "components/landing-pages/img/referral/getting-started-1.jpeg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      step2: file(
        relativePath: {
          eq: "components/landing-pages/img/referral/getting-started-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      step3: file(
        relativePath: {
          eq: "components/landing-pages/img/referral/getting-started-3.jpeg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
