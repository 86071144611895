import { useSpring, animated } from "@react-spring/web";
import TextLinkV2 from "components/common/TextLinkV2";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { useIsVisible } from "hooks/useIsVisible";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { useEffect, useMemo, useRef, useState } from "react";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5rem;
`;

const ScrollContainer = styled.div`
  display: flex;
  gap: 5rem;
  position: relative;
`;

const AnimationCol = styled.div`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

const StickyCol = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 7.5rem;
  width: 100%;
`;

const Frame = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
`;

const imageStyles = css`
  width: 100%;
  height: 500px !important;
  overflow: hidden;
  border-radius: 1rem;
`;

export const StepCircle = styled.div`
  height: 52px;
  width: 52px;
  background-color: var(--blue-500);
  border-radius: 50%;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-weight: 900;
  padding: 0.25rem;
`;

const SectionTitle = styled(FancyTitle)`
  text-align: center;
`;

const AnimatedScrollSteps = () => {
  const [stepNumber, setStepNumber] = useState(1);

  const frame1Ref = useRef(null);
  const frame2Ref = useRef(null);
  const frame3Ref = useRef(null);

  const frame1Visible = useIsVisible(frame1Ref);
  const frame2Visible = useIsVisible(frame2Ref);
  const frame3Visible = useIsVisible(frame3Ref);

  const [props, api] = useSpring(
    () => ({
      from: { opacity: 0, transform: "translateX(-500px)" },
      to: { opacity: 1, transform: "translateX(0)" },
      config: { mass: 2 },
    }),
    []
  );

  useEffect(() => {
    api.start({
      from: { opacity: 0, transform: "translateX(-500px)" },
      to: { opacity: 1, transform: "translateX(0)" },
    });
  }, [stepNumber]);

  useEffect(() => {
    if (frame1Visible) {
      setStepNumber(1);
    }
    if (frame2Visible && !frame1Visible) {
      setStepNumber(2);
    }
    if (frame3Visible && !frame2Visible) {
      setStepNumber(3);
    }
  }, [frame1Visible, frame2Visible, frame3Visible]);

  const stepTitle = useMemo(() => {
    switch (stepNumber) {
      case 1:
        return "Share Rhombus with a Friend";
      case 2:
        return "Your Referral Becomes a Rhombus Customer";
      case 3:
        return "Start Enjoying Your Rewards";
      default:
        return "Share Rhombus with a Friend";
    }
  }, [stepNumber]);

  const stepDescription = useMemo(() => {
    switch (stepNumber) {
      case 1:
        return "Let your friend know about Rhombus as a possible solution to meet their needs. Complete the form above to submit your referral and put us in touch. ";
      case 2:
        return "The Rhombus team will reach out to your referral to see what value we can provide for their business and offer a tailored solution for their needs.";
      case 3:
        return "After your referral makes a purchase, you'll receive a digital gift card, up to $500*.";
      default:
        return "Complete a Rhombus Referral form or use your unique link to invite your referral to Rhombus.";
    }
  }, [stepNumber]);

  return (
    <SectionContainer style={{ overflow: "visible" }}>
      <SectionInner>
        <ContentContainer>
          <SectionTitle>Getting Started</SectionTitle>
          <ScrollContainer>
            <AnimationCol>
              <StickyCol>
                <Frame>
                  <StepCircle>{stepNumber}</StepCircle>
                  <animated.div style={props}>
                    <TitleMed color="var(--blue-500)">{stepTitle}</TitleMed>
                  </animated.div>
                  <animated.div style={props}>
                    <MainParagraph
                      style={{ fontSize: "18px", maxWidth: "550px" }}
                    >
                      {stepDescription}
                    </MainParagraph>
                  </animated.div>
                  {stepNumber === 3 && (
                    <animated.div style={props}>
                      <TextLinkV2
                        path="https://info.rhombus.com/rhombus-referral-program"
                        title="*View full terms and conditions"
                        target="_blank"
                      />
                    </animated.div>
                  )}
                </Frame>
              </StickyCol>
            </AnimationCol>
            <AnimationCol>
              <Frame ref={frame1Ref}>
                <StaticImage
                  src="../img/referral/getting-started-1.jpeg"
                  objectFit="cover"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={frame2Ref}>
                <StaticImage
                  src="../img/referral/getting-started-2.png"
                  objectFit="cover"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={frame3Ref}>
                <StaticImage
                  src="../img/referral/getting-started-3.jpeg"
                  objectFit="cover"
                  className={imageStyles}
                />
              </Frame>
            </AnimationCol>
          </ScrollContainer>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default AnimatedScrollSteps;
