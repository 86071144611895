import TextLinkV2 from "components/common/TextLinkV2";
import { toTitleCase } from "components/common/util/helperFunctions";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { StepCircle } from "./AnimatedScrollSteps";

const TextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const imageStyles = css`
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 2.5rem;
`;

const MobileStepsSections = ({ steps, title }) => {
  return (
    <SectionContainer>
      <SectionInner
        style={{ flexDirection: "column", alignItems: "center", gap: "5rem" }}
      >
        <FancyTitle style={{ textAlign: "center" }}>{title}</FancyTitle>
        {steps.map(item => (
          <TextBox>
            <TextContainer
              style={{ width: "665px", maxWidth: "100%", gap: "1.25rem" }}
            >
              <GatsbyImage
                image={getImage(item.image)}
                alt={item.title}
                className={imageStyles}
                objectFit="cover"
              />
              <StepCircle>{item.stepCount}</StepCircle>
              <TitleMed color="var(--blue-500)">
                {toTitleCase(item.title)}
              </TitleMed>
              <MainParagraph>{item.description}</MainParagraph>
              {item.stepCount === 3 && (
                <TextLinkV2
                  path="https://info.rhombus.com/rhombus-referral-program"
                  title="*View full terms and conditions"
                  target="_blank"
                />
              )}
            </TextContainer>
          </TextBox>
        ))}
      </SectionInner>
    </SectionContainer>
  );
};

export default MobileStepsSections;
